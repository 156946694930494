.center {
	width: 100%;
	height: 100%;
	background-color: white;
	position: absolute;
	left: 0;
	top: 0;
}

.aviWrap {
	text-align: center;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.aviWrap>h1 {
	overflow: hidden;
	white-space: nowrap;
}

.aviWrap>img {
	max-inline-size: 100%;
}

.aviWrap>* {
	block-size: auto;
}

#Avi {
	pointer-events: none;
	user-select: none;
	cursor: default;
}