.bioWrap {
	width: 75%;
	display: flex;
	align-items: center;
	max-height: 80%;
	overflow: auto;
}

.bioWrap>* {
	padding: 2rem;
}

#bioPic {
	border-radius: 45%;
	min-width: 35vw;
	flex: 2 1 0;
}

@media screen and (min-width: 1800px) {
	.bioWrap {
		overflow: hidden;
	}
}

@media screen and (max-width: 1800px) {
	.bioWrap {
		flex-direction: column;
	}
}

@media screen and (max-width: 1800px) and (min-width:800px) {
	#bioPic {
		min-width: 25vw;
	}
}

@media screen and (max-width:800px) {
	.bioWrap>* {
		padding: 1rem;
	}

	.bioWrap {
		padding-top: 10vh;
	}
}