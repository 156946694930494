.gameItem {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	margin: 1rem;
	align-items: center;
	flex: 1 0 21%;
	border-radius: 10%;
}

.gameItem>img {
	border-radius: 50%;
}

#gameWrap {
	display: flex;
	flex-wrap: wrap;
	width: 80%;
	height: 80%;
	justify-content: space-around;
}

.game-1 {
	background-color: #ffaeb5;
}