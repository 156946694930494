@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Concert+One&family=Patrick+Hand+SC&display=swap");

h1,
h2,
h3,
h4 {
	font-family: "Patrick Hand SC";
}

body {
	font-family: "Bebas Neue";
}

body * {
	line-height: 1.5em;
	font-size: calc(1rem + 0.4vw);
}

.App-header {
	height: 85vh;
}

a:focus-visible {
	border: 2px #fb6376 solid;
}

#nameTitle {
	font-size: calc(3rem + 0.4vw);
	font-family: "Patrick Hand SC";
	overflow: hidden;
	white-space: nowrap;
}

#newHead {
	text-align: left;
	border-radius: 1em;
	background-color: white;
	width: 45%;
	padding: 1em 5em 1em 4vmin;
	margin: 10em auto;
	position: relative;
}

#newTag {
	position: absolute;
	top: -10%;
	left: 85%;
	width: min(30%, 25%);
	z-index: 1;
}

#introList li {
	margin-bottom: 1rem;
}

.sectionHeader {
	background: white;
	border-radius: 50%;
	padding: 2vmin;
	width: fit-content;
	box-shadow: 0px 0px 5px 5px #fb6376;
	margin-left: 2rem;
}

.info {
	padding: 0 3rem;
	font-family: "Concert One";
}

.scrollSection {
	margin-bottom: 2rem;
	overflow: auto;
}

.sectionInfo {
	width: 100vw;
	height: 100vh;
	align-items: center;
	margin: auto;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	background-color: white;
}

.columnSection {
	flex-direction: column;
	overflow-y: scroll;
}